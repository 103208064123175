import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICongregationInfo } from '../model/stats/Icongregation.info.model';
import { IMembershipAttendance } from '../model/stats/Imembership.attendance.model';
import { IFinances } from '../model/stats/Ifinances';
import { ChristianEducationEnrollment } from '../model/stats/christian.education.enrollment.model';
import { IMembershipDemographics } from '../model/stats/Imembership.demographics.model';
import { ICongregationStat } from '../model/stats/Icongregation.stat';
import { percentDiff } from '../shared/utilities';
import { ICongregationStatVariances } from '../model/stats/Icongregation.stat.variances';
import { FormGroup } from '@angular/forms';
import { IEmailMessage } from 'api/model/IEmail.message';

@Injectable({
  providedIn: 'root'
})
export class CongregationStatService {

  private originalMembershipBaptizedSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  OriginalMembershipBaptized$: Observable<number> = this.originalMembershipBaptizedSubject.asObservable();
  private originalMembershipConfirmedSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  OriginalMembershipConfirmed$: Observable<number> = this.originalMembershipConfirmedSubject.asObservable();
  private originalWeeklyAttendAvgSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  OriginalWeeklyAttendAvg$: Observable<number> = this.originalWeeklyAttendAvgSubject.asObservable();
  private originalAtHomeExpensesSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  OriginalAtHomeExpenses$: Observable<number> = this.originalAtHomeExpensesSubject.asObservable();
  private originalIncomeContributionsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  OriginalIncomeContributions$: Observable<number> = this.originalIncomeContributionsSubject.asObservable();

  private CongregationStatsSubject: BehaviorSubject<ICongregationStat> = new BehaviorSubject<ICongregationStat>(null);
  CongregationStats$: Observable<ICongregationStat> = this.CongregationStatsSubject.asObservable();

  private CongregationPrevStatsSubject: BehaviorSubject<ICongregationStat> = new BehaviorSubject<ICongregationStat>(null);
  CongregationPrevStats$: Observable<ICongregationStat> = this.CongregationPrevStatsSubject.asObservable();

  private CongregationInfoSubject: BehaviorSubject<ICongregationInfo> = new BehaviorSubject(null);
  CongregationInfo$: Observable<ICongregationInfo> = this.CongregationInfoSubject.asObservable();

  private statsAreValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ValidStats$: Observable<boolean> = this.statsAreValidSubject.asObservable();

  private statsHaveVarianceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  StatsHaveVariance$: Observable<boolean> = this.statsHaveVarianceSubject.asObservable();

  private statsConvertedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  StatsConverted$: Observable<boolean> = this.statsConvertedSubject.asObservable();

  private sbcTabVisitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sbcTabVisited$: Observable<boolean> = this.sbcTabVisitedSubject.asObservable();

  private showPreviousStatsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ShowPreviousStats$: Observable<boolean> = this.showPreviousStatsSubject.asObservable();

  sbcTabToggle(): void {
    this.sbcTabVisitedSubject.next(true);
  }

  private wbcTabVisitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  wbcTabVisited$: Observable<boolean> = this.wbcTabVisitedSubject.asObservable();

  wbcTabToggle(): void {
    this.wbcTabVisitedSubject.next(true);
  }

  private ccTabVisitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ccTabVisited$: Observable<boolean> = this.ccTabVisitedSubject.asObservable();

  ccTabToggle(): void {
    this.ccTabVisitedSubject.next(true);
  }

  private vbsTabVisitedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  vbsTabVisited$: Observable<boolean> = this.vbsTabVisitedSubject.asObservable();

  vbsTabToggle(): void {
    this.vbsTabVisitedSubject.next(true);
  }

  MembershipConfirmedHasVarianceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  MembershipConfirmedHasVariance$: Observable<boolean> = this.MembershipConfirmedHasVarianceSubject.asObservable();

  MembershipBaptizedHasVarianceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  MembershipBaptizedHasVariance$: Observable<boolean> = this.MembershipBaptizedHasVarianceSubject.asObservable();

  WeeklyAttendAvgHasVarianceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  WeeklyAttendAvgHasVariance$: Observable<boolean> = this.WeeklyAttendAvgHasVarianceSubject.asObservable();

  MembershipAttendanceIsValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  MembershipAttendanceIsValid$: Observable<boolean> = this.MembershipAttendanceIsValidSubject.asObservable();

  IncomeContributionsHasVarianceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  IncomeContributionsHasVariance$: Observable<boolean> = this.IncomeContributionsHasVarianceSubject.asObservable();

  AtHomeOperExpensesHasVarianceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  AtHomeOperExpensesHasVariance$: Observable<boolean> = this.AtHomeOperExpensesHasVarianceSubject.asObservable();

  FinancesAreValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  FinancesAreValid$: Observable<boolean> = this.FinancesAreValidSubject.asObservable();

  DemographicsAreValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  DemographicsAreValid$: Observable<boolean> = this.DemographicsAreValidSubject.asObservable();
  DemographicTotalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  DemographicTotal$: Observable<number> = this.DemographicTotalSubject.asObservable();

  ConfirmedGreaterThanBaptizedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ConfirmedGreaterThanBaptized$: Observable<boolean> = this.ConfirmedGreaterThanBaptizedSubject.asObservable();

  NonMemberAttendGreaterThanMemberAttendSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  NonMemberAttendGreaterThanMemberAttend$: Observable<boolean> = this.NonMemberAttendGreaterThanMemberAttendSubject.asObservable();

  constructor(private http: HttpClient) {

  }

  getCongregationInfo(id: number) {
    this.http.get<ICongregationInfo>(`/api/congregation/info/${id}`).subscribe(
      c => {
        this.CongregationInfoSubject.next(c);
      }
    )
  }

  getCongregationStats(id: number, year: number) {
    this.http.get<ICongregationStat>(`/api/congregation/stats/${id}/${year}`).subscribe(
      stats => {

        if (stats && stats.Complete) {
          this.getFinalizedCongregationStats(id, year);
        } else {
          this.originalAtHomeExpensesSubject.next(stats.AtHomeOperExpenses);
          this.originalIncomeContributionsSubject.next(stats.IncomeContributions);
          this.originalMembershipBaptizedSubject.next(stats.MembershipBaptized);
          this.originalMembershipConfirmedSubject.next(stats.MembershipConfirmed);
          this.originalWeeklyAttendAvgSubject.next(stats.WeeklyAttendAvg);

          this.CongregationStatsSubject.next(stats);
          this.validateStats(stats);
          this.CheckforVariances(stats);
        }

      }, (err) => {
        console.log(err);
      }
    )
  }

  private getFinalizedCongregationStats(id: number, year: number) {
    this.http.get<ICongregationStat>(`/api/congregation/finalizedstats/${id}/${year}`).subscribe(
      stats => {
        this.CongregationStatsSubject.next(stats);
      }, (err) => {
        console.log(err);
      }
    )
  }

  CheckforVariances(stats: ICongregationStat) {
    let AtHomeOperExpensesVariance = this.hasAtHomeOperExpensesVariance(stats);
    let IncomeContributionsVariance = this.hasIncomeContributionsVariance(stats);
    let MembershipBaptizedVariance = this.hasMembershipBaptizedVariance(stats);
    let MembershipConfirmedVariance = this.hasMembershipConfirmedVariance(stats);
    let WeeklyAttendAvgVariance = this.hasWeeklyAttendAvgVariance(stats);
    let statsHaveVariance: boolean = AtHomeOperExpensesVariance
      || IncomeContributionsVariance || MembershipBaptizedVariance
      || MembershipConfirmedVariance || WeeklyAttendAvgVariance;
    this.statsHaveVarianceSubject.next(statsHaveVariance);
  }

  getCongregationPrevStats(id: number) {
    this.http.get<ICongregationStat>(`/api/congregation/prevstats/${id}`).subscribe(
      stats => {
        this.CongregationPrevStatsSubject.next(stats);
      }, (err) => {
        console.log(err);
      }
    )
  }

  updateMembershipAttendance(stats: ICongregationStat): Observable<boolean> {
    this.statsChanged(stats);
    return this.SaveStats();
  }

  updateFinances(stats: ICongregationStat): Observable<boolean> {
    this.statsChanged(stats);
    return this.SaveStats();
  }

  updateMembershipDemographics(stats: ICongregationStat): Observable<boolean> {
    this.statsChanged(stats);
    return this.SaveStats();
  }

  submitStats(id: string): Observable<boolean> {
    return this.http.post<boolean>(`/api/congregation/submitstats/${id}`, {});
  }

  AutoSaveStats() {
    if (!this.CongregationInfoSubject.value || !this.CongregationStatsSubject.value
      || this.CongregationStatsSubject.value.Complete) return false;

    let year = this.CongregationStatsSubject.value.StatYear;

    this.SaveStats().subscribe(result => {
      if (!result) {
        console.log('There was an error auto saving stats.');
      } else {
        this.getCongregationStats(this.CongregationInfoSubject.value.LookupID, +year);
      }
    });
  }

  SaveStats(): Observable<boolean> {
    let id = this.CongregationInfoSubject.value.CongID;
    let year = this.CongregationStatsSubject.value.StatYear;
    return this.http.put<boolean>(`/api/congregation/savestats/${id}/${year}`, this.CongregationStatsSubject.value);
  }

  statsChanged(stats: ICongregationStat, tab: number = -1) {

    if (tab == 0) {
      this.sbcTabVisitedSubject.next(true);
    }
    else if (tab == 1) {
      this.wbcTabVisitedSubject.next(true);
    } else if (tab == 2) {
      this.ccTabVisitedSubject.next(true);
    } else if (tab == 3) {
      this.vbsTabVisitedSubject.next(true);
    }

    this.CongregationStatsSubject.next(stats);

    this.validateStats(stats);
  }

  private validateStats(stats: ICongregationStat) {

    let validFinances = this.financesAreValid(stats);
    let validMembershipAttendance = this.membershipAttendanceIsValid(stats);
    let validDemographics = this.membershipDemographicsAreValid(stats);

    this.MembershipAttendanceIsValidSubject.next(validMembershipAttendance);
    this.FinancesAreValidSubject.next(validFinances);
    this.DemographicsAreValidSubject.next(validDemographics);

    let valid = validMembershipAttendance && validFinances
      && validDemographics;
    this.statsAreValidSubject.next(valid);
  }

  private membershipAttendanceIsValid(stats: ICongregationStat): boolean {

    if (!stats) return false;

    let baptizedGreaterThanConfirmed = +stats.MembershipBaptized >= +stats.MembershipConfirmed;
    this.ConfirmedGreaterThanBaptizedSubject.next(!baptizedGreaterThanConfirmed);

    let weeklyAvgAttendGreaterThanNonMember = !stats.SunNonMembers || +stats.WeeklyAttendAvg >= +stats.SunNonMembers;
    this.NonMemberAttendGreaterThanMemberAttendSubject.next(!weeklyAvgAttendGreaterThanNonMember);

    var valid = stats && stats.MembershipBaptized && +stats.MembershipBaptized > 0
      && stats.MembershipConfirmed && +stats.MembershipConfirmed > 0
      && stats.WeeklyAttendAvg && +stats.WeeklyAttendAvg > 0
      && baptizedGreaterThanConfirmed
      && weeklyAvgAttendGreaterThanNonMember;
    return valid;
  }

  private financesAreValid(stats: ICongregationStat): boolean {
    console.log(stats)
    if (!stats) return false;

    //disbursements
    let AtHomeOperExpenses = stats.AtHomeOperExpenses || 0;
    let DisbursementsDistrictSynod = stats.DisbursementsDistrictSynod || 0;
    let DisbursementsOtherOrgs = stats.DisbursementsOtherOrgs || 0;
    let disbursementSum = AtHomeOperExpenses + DisbursementsDistrictSynod + DisbursementsOtherOrgs;

    //receipts
    let IncomeContributions = stats.IncomeContributions || 0;
    let IncomeOther = stats.IncomeOther || 0;
    let receiptSum = IncomeContributions + IncomeOther;

    var valid = (disbursementSum == 0 || IncomeContributions > 0) && (receiptSum == 0 || AtHomeOperExpenses > 0);
    return valid;
  }

  private membershipDemographicsAreValid(stats: ICongregationStat): boolean {

    if (!stats) return false;

    let PercentMemUnder15 = stats && stats.PercentMemUnder15 ? +stats.PercentMemUnder15 : 0;
    let PercentMem15_18 = stats && stats.PercentMem15_18 ? +stats.PercentMem15_18 : 0;
    let PercentMem19_34 = stats && stats.PercentMem19_34 ? +stats.PercentMem19_34 : 0;
    let PercentMem35_49 = stats && stats.PercentMem35_49 ? +stats.PercentMem35_49 : 0;
    let PercentMem50_64 = stats && stats.PercentMem50_64 ? +stats.PercentMem50_64 : 0;
    let PercentMemOver64 = stats && stats.PercentMemOver64 ? +stats.PercentMemOver64 : 0;
    let total = (PercentMemUnder15 + PercentMem15_18 + PercentMem19_34 + PercentMem35_49 + PercentMem50_64 + PercentMemOver64);
    this.DemographicTotalSubject.next(total);
    var valid = total == 100 || total == 0;
    return valid;
  }

  private hasMembershipConfirmedVariance(stats: ICongregationStat): boolean {
    if (!stats) return false;
    let membershipConfirmed = isNaN(stats.MembershipConfirmed) ? 0 : +stats.MembershipConfirmed;
    let prevMembershipConfirmed = isNaN(stats.PrevMembershipConfirmed) ? 0 : +stats.PrevMembershipConfirmed;
    let varianceOptionSelected = this.CongregationStatsSubject.value.MembershipConfirmedUpdateCurrentValue || this.CongregationStatsSubject.value.MembershipConfirmedUpdatePreviousValue
      || this.CongregationStatsSubject.value.MembershipConfirmedValuesAreCorrect;
    let variance = percentDiff(membershipConfirmed, prevMembershipConfirmed);
    let hasVariance = !!stats
      && !!stats.MembershipConfirmed && !!stats.PrevMembershipConfirmed && !varianceOptionSelected
      && variance > 10;
    this.MembershipConfirmedHasVarianceSubject.next(hasVariance);
    return hasVariance;
  }

  private hasMembershipBaptizedVariance(stats: ICongregationStat): boolean {
    if (!stats) return false;
    let membershipBaptized = isNaN(stats.MembershipBaptized) ? 0 : +stats.MembershipBaptized;
    let prevMembershipBaptized = isNaN(stats.PrevMembershipBaptized) ? 0 : +stats.PrevMembershipBaptized;
    let varianceOptionSelected = this.CongregationStatsSubject.value.MembershipBaptizedUpdateCurrentValue || this.CongregationStatsSubject.value.MembershipBaptizedUpdatePreviousValue
      || this.CongregationStatsSubject.value.MembershipBaptizedValuesAreCorrect;
    let variance = percentDiff(membershipBaptized, prevMembershipBaptized);
    let hasVariance = !!stats
      && !!stats.MembershipBaptized && !!stats.PrevMembershipBaptized && !varianceOptionSelected
      && variance > 10;
    this.MembershipBaptizedHasVarianceSubject.next(hasVariance);
    return hasVariance;
  }

  private hasWeeklyAttendAvgVariance(stats: ICongregationStat): boolean {
    if (!stats) return false;
    let WeeklyAttendAvg = isNaN(stats.WeeklyAttendAvg) ? 0 : +stats.WeeklyAttendAvg;
    let prevWeeklyAttendAvg = isNaN(stats.PrevWeeklyAttendAvg) ? 0 : +stats.PrevWeeklyAttendAvg;
    let variance = percentDiff(WeeklyAttendAvg, prevWeeklyAttendAvg);
    let varianceOptionSelected = this.CongregationStatsSubject.value.WeeklyAttendAvgUpdateCurrentValue || this.CongregationStatsSubject.value.WeeklyAttendAvgUpdatePreviousValue
      || this.CongregationStatsSubject.value.WeeklyAttendAvgValuesAreCorrect;
    let hasVariance = !!stats
      && !!stats.WeeklyAttendAvg && !!stats.PrevWeeklyAttendAvg && !varianceOptionSelected
      && variance > 10;
    this.WeeklyAttendAvgHasVarianceSubject.next(hasVariance);
    return hasVariance;
  }

  private hasIncomeContributionsVariance(stats: ICongregationStat): boolean {
    if (!stats) return false;
    let incomeContributions = isNaN(stats.IncomeContributions) ? 0 : +stats.IncomeContributions;
    let prevIncomeContributions = isNaN(stats.PrevIncomeContributions) ? 0 : +stats.PrevIncomeContributions;
    let variance = percentDiff(incomeContributions, prevIncomeContributions);
    let varianceOptionSelected = this.CongregationStatsSubject.value.IncomeContributionsUpdateCurrentValue || this.CongregationStatsSubject.value.IncomeContributionsUpdatePreviousValue
      || this.CongregationStatsSubject.value.IncomeContributionsValuesAreCorrect
    let hasVariance = !!stats
      && !!stats.IncomeContributions && !!stats.PrevIncomeContributions && !varianceOptionSelected
      && variance > 10;
    this.IncomeContributionsHasVarianceSubject.next(hasVariance);
    return hasVariance;
  }

  private hasAtHomeOperExpensesVariance(stats: ICongregationStat): boolean {
    if (!stats) return false;
    let atHomeOperExpenses = isNaN(stats.AtHomeOperExpenses) ? 0 : +stats.AtHomeOperExpenses;
    let prevAtHomeOperExpenses = isNaN(stats.PrevAtHomeOperExpenses) ? 0 : +stats.PrevAtHomeOperExpenses;
    let variance = percentDiff(atHomeOperExpenses, prevAtHomeOperExpenses);
    let varianceOptionSelected = this.CongregationStatsSubject.value.AtHomeOperExpensesUpdateCurrentValue || this.CongregationStatsSubject.value.AtHomeOperExpensesUpdatePreviousValue
      || this.CongregationStatsSubject.value.AtHomeOperExpensesValuesAreCorrect;
    let hasVariance = !!stats
      && !!stats.AtHomeOperExpenses && !!stats.PrevAtHomeOperExpenses && !varianceOptionSelected
      && variance > 10;
    this.AtHomeOperExpensesHasVarianceSubject.next(hasVariance);
    return hasVariance;
  }

  updateStatVariances() {
    return this.SaveStats();
  }

  CalculateDemographicPercentages(demographics: IMembershipDemographics): IMembershipDemographics {
    let sum = 0;
    Object.keys(demographics).forEach(d => {
      sum += isNaN(demographics[d]) ? 0 : +demographics[d];
    })
    if (sum == 100) {
      this.statsConvertedSubject.next(false);
      return demographics;
    } else if (sum == 0) {
      this.statsConvertedSubject.next(false);
    }
    else {
      this.statsConvertedSubject.next(true);
    }
    let total = 0;
    let key = '';
    let greatest = 0;
    Object.keys(demographics).forEach(d => {
      let perc = isNaN(demographics[d]) ? 0 : Math.floor(Math.round((+demographics[d] / sum) * 100));
      total += perc;
      demographics[d] = perc;
      if (perc > greatest) {
        greatest = perc;
        key = d;
      }
    });
    let diff = 100 - total;
    demographics[key] = diff < 100 ? demographics[key] + diff : demographics[key] - diff;
    return demographics;

  }

  clearStats() {
    this.CongregationStatsSubject.next(null);
    this.showPreviousStatsSubject.next(false);
  }

  varianceOptionChanged(controlName: string, form: FormGroup, associatedInput: ElementRef = null) {
    let currentControl = form.controls[controlName];
    var selected: boolean = currentControl.value as boolean;
    switch (controlName) {
      case "WeeklyAttendAvgUpdateCurrentValue":
        form.controls["WeeklyAttendAvgValuesAreCorrect"].setValue(false);
        let WeeklyAttendAvg = form.controls["WeeklyAttendAvg"];
        if (selected) {
          WeeklyAttendAvg.enable();
          if (associatedInput) {
            associatedInput.nativeElement.focus();
          }
        } else {
          WeeklyAttendAvg.disable();
        }
        break;
      case "WeeklyAttendAvgValuesAreCorrect":
        form.controls["WeeklyAttendAvgUpdateCurrentValue"].setValue(false);
        form.controls["WeeklyAttendAvgUpdatePreviousValue"].setValue(false);
        form.controls["WeeklyAttendAvg"].disable();
        form.controls["PrevWeeklyAttendAvg"].disable();
        break;
      case 'WeeklyAttendAvgUpdatePreviousValue':
        form.controls["WeeklyAttendAvgValuesAreCorrect"].setValue(false);
        let PrevWeeklyAttendAvg = form.controls["PrevWeeklyAttendAvg"];
        if (currentControl.value) {
          PrevWeeklyAttendAvg.enable();
          setTimeout(() => {
            if (associatedInput) {
              associatedInput.nativeElement.focus();
            }
          }, 100);

        } else {
          PrevWeeklyAttendAvg.disable();
          form.controls["WeeklyAttendAvgUpdatePreviousValue"].setValue(null);
        }
        break;
      case "MembershipBaptizedUpdateCurrentValue":
        form.controls["MembershipBaptizedValuesAreCorrect"].setValue(false);
        let MembershipBaptized = form.controls["MembershipBaptized"];
        if (selected) {
          MembershipBaptized.enable();
          if (associatedInput) {
            associatedInput.nativeElement.focus();
          }
        } else {
          MembershipBaptized.disable();
        }
        break;
      case "MembershipBaptizedValuesAreCorrect":
        form.controls["MembershipBaptizedUpdateCurrentValue"].setValue(false);
        form.controls["MembershipBaptizedUpdatePreviousValue"].setValue(false);
        form.controls["MembershipBaptized"].disable();
        form.controls["PrevMembershipBaptized"].disable();
        break;
      case 'MembershipBaptizedUpdatePreviousValue':
        form.controls["MembershipBaptizedValuesAreCorrect"].setValue(false);
        let PrevMembershipBaptized = form.controls["PrevMembershipBaptized"];
        if (currentControl.value) {
          PrevMembershipBaptized.enable();
          setTimeout(() => {
            if (associatedInput) {
              associatedInput.nativeElement.focus();
            }
          }, 100);
        } else {
          PrevMembershipBaptized.disable();
          form.controls["MembershipBaptizedUpdatePrevValue"].setValue(null);
        }
        break;
      case "MembershipConfirmedUpdateCurrentValue":
        form.controls["MembershipConfirmedValuesAreCorrect"].setValue(false);
        let MembershipConfirmed = form.controls["MembershipConfirmed"];
        if (selected) {
          MembershipConfirmed.enable();
          if (associatedInput) {
            associatedInput.nativeElement.focus();
          }
        } else {
          MembershipConfirmed.disable();
        }
        break;
      case "MembershipConfirmedValuesAreCorrect":
        form.controls["MembershipConfirmedUpdateCurrentValue"].setValue(false);
        form.controls["MembershipConfirmedUpdatePreviousValue"].setValue(false);
        form.controls["MembershipConfirmed"].disable();
        form.controls["PrevMembershipConfirmed"].disable();
        break;
      case 'MembershipConfirmedUpdatePreviousValue':
        form.controls["MembershipConfirmedValuesAreCorrect"].setValue(false);
        let PrevMembershipConfirmed = form.controls["PrevMembershipConfirmed"];
        if (currentControl.value) {
          PrevMembershipConfirmed.enable();
          setTimeout(() => {
            if (associatedInput) {
              associatedInput.nativeElement.focus();
            }
          }, 100);
        } else {
          PrevMembershipConfirmed.disable();
          form.controls["MembershipConfirmedUpdatePrevValue"].setValue(null);
        }
        break;
      case "AtHomeOperExpensesUpdateCurrentValue":
        form.controls["AtHomeOperExpensesValuesAreCorrect"].setValue(false);
        let AtHomeOperExpenses = form.controls["AtHomeOperExpenses"];
        if (selected) {
          AtHomeOperExpenses.enable();
          if (associatedInput) {
            associatedInput.nativeElement.focus();
          }
        } else {
          AtHomeOperExpenses.disable();
        }
        break;
      case "AtHomeOperExpensesValuesAreCorrect":
        form.controls["AtHomeOperExpensesUpdateCurrentValue"].setValue(false);
        form.controls["AtHomeOperExpensesUpdatePreviousValue"].setValue(false);
        form.controls["AtHomeOperExpenses"].disable();
        form.controls["PrevAtHomeOperExpenses"].disable();
        break;
      case 'AtHomeOperExpensesUpdatePreviousValue':
        form.controls["AtHomeOperExpensesValuesAreCorrect"].setValue(false);
        let PrevAtHomeOperExpenses = form.controls["PrevAtHomeOperExpenses"];
        if (currentControl.value) {
          PrevAtHomeOperExpenses.enable();
          setTimeout(() => {
            if (associatedInput) {
              associatedInput.nativeElement.focus();
            }
          }, 100);
        } else {
          PrevAtHomeOperExpenses.disable();
          form.controls["AtHomeOperExpensesUpdatePrevValue"].setValue(null);
        }
        break;
      case "IncomeContributionsUpdateCurrentValue":
        form.controls["IncomeContributionsValuesAreCorrect"].setValue(false);
        let IncomeContributions = form.controls["IncomeContributions"];
        if (selected) {
          IncomeContributions.enable();
          if (associatedInput) {
            associatedInput.nativeElement.focus();
          }
        } else {
          IncomeContributions.disable();
        }
        break;
      case "IncomeContributionsValuesAreCorrect":
        form.controls["IncomeContributionsUpdateCurrentValue"].setValue(false);
        form.controls["IncomeContributionsUpdatePreviousValue"].setValue(false);
        form.controls["IncomeContributions"].disable();
        form.controls["PrevIncomeContributions"].disable();
        break;
      case 'IncomeContributionsUpdatePreviousValue':
        form.controls["IncomeContributionsValuesAreCorrect"].setValue(false);
        let PrevIncomeContributions = form.controls["PrevIncomeContributions"];
        if (currentControl.value) {
          PrevIncomeContributions.enable();
          setTimeout(() => {
            if (associatedInput) {
              associatedInput.nativeElement.focus();
            }
          }, 100);
        } else {
          PrevIncomeContributions.disable();
          form.controls["IncomeContributionsUpdatePrevValue"].setValue(null);
        }
        break;
    }
  }

  confirmedGreaterThanBaptized(stats: ICongregationStat): boolean {
    return stats && !isNaN(stats.MembershipBaptized) && !isNaN(stats.MembershipConfirmed)
      && +stats.MembershipBaptized < +stats.MembershipConfirmed;
  }

  NonMemberAttendGreaterThanMemberAttend(stats: ICongregationStat): boolean {
    return stats && !isNaN(stats.WeeklyAttendAvg) && !isNaN(stats.SunNonMembers)
      && +stats.WeeklyAttendAvg < +stats.SunNonMembers;
  }

  sendMail(message: IEmailMessage): void {
    this.http.post('/api/utilities/sendmail', message).subscribe(result => {

    })
  }

  showPreviousStats() {
    let toggle = !this.showPreviousStatsSubject.value;
    this.showPreviousStatsSubject.next(toggle);
  }

  clearCongStatData(): void {
    this.CongregationStatsSubject.next(null);
  }

  statYear(): number {
    return new Date().getFullYear() -1;
  }

}
