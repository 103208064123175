import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ICongregationInfo } from 'src/app/model/stats/Icongregation.info.model';
import { Router } from '@angular/router';
import { CongregationStatService } from 'src/app/services/congregation.stat.service';
import { SubscriptionLike } from 'rxjs';

@Component({
    selector: 'Cong-Info',
    templateUrl: './congregation.info.component.html'
})
export class CongregationInfoComponent implements OnInit, OnChanges, OnDestroy {


    @Input() Info: ICongregationInfo;
    StatYears: string[] = [];
    CongStatInfo: ICongregationInfo;
    CongStatInfoSub: SubscriptionLike;

    CongStatSub: SubscriptionLike;
    constructor(private congStatService: CongregationStatService, private router: Router) {

    }
    ngOnInit(): void {
        this.CongStatInfoSub = this.congStatService.CongregationInfo$.subscribe(c => this.CongStatInfo = c);
    }

    OpenStatForm(year: string) {
        window.open('api/export/stats/' + year + '/' + this.CongStatInfo.LookupID);
    }

    ViewAll(){
        this.router.navigateByUrl('/csr/lyrtable');
    }

    updateYears(): void{
        //this.CongStatInfoSub = this.congStatService.CongregationInfo$.subscribe(c => this.CongStatInfo = c);
        //this.StatYears = this.Info && this.Info.StatYears ? this.Info.StatYears.split(',') : [];

    };
    ngOnChanges(changes: any): void {
        //this.updateYears();
        this.StatYears = this.Info && this.Info.StatYears ? this.Info.StatYears.split(',') : [];
    }

    ngOnDestroy(): void {
        if (this.CongStatInfoSub) { this.CongStatInfoSub.unsubscribe(); }
    }

}