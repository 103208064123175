import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';


@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        let sessionReq = req.clone({
            setHeaders: { 'x-congstat-session': '' }
        });
        return next.handle(sessionReq);
    }
}