
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, FormArray, FormGroup, AsyncValidatorFn } from '@angular/forms';
import { ValidationMessages } from '../shared/validation/validation.messages';
import * as moment from 'moment';
import { filter, max, orderBy } from 'lodash';
import { SubscriptionLike, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

    constructor(private http: HttpClient) { }

    validateFormControl(c: AbstractControl, fieldName: string, messageOverride: object = {}): string[] {
        let errorMessages: string[] = [];//clear any existing errors
        if ((c.touched || c.dirty) && c.errors) {
            let validationMessage: ValidationMessages = new ValidationMessages();
            Object.keys(c.errors).map((key) => {
                if (messageOverride[key]) {
                    errorMessages.push(messageOverride[key]);
                }
                else if (key == 'maxlength' || key == 'minlength') {
                    errorMessages.push(fieldName + validationMessage[key] + c.errors[key].requiredLength);
                }
                else if (key == 'max') {
                    errorMessages.push(fieldName + validationMessage[key] + c.errors[key].max);
                }
                else if (key == 'min') {
                    errorMessages.push(fieldName + validationMessage[key] + c.errors[key].min);
                }
                else if (key == 'namenonumbers' || key == 'uniquephonevalidator') {
                    errorMessages.push(validationMessage[key]);
                }
                else {
                    errorMessages.push(fieldName + validationMessage[key]);
                }
            })
        }
        return errorMessages;
    }
    /**Number of milliseconds of idle time a form control will wait before firing validation */
    ValidationDelay: number = 1000;

    NameNoNumbers(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let regex = new RegExp(/^[a-z '/-]+$/i);
            const valid = regex.test(control.value);
            return valid || control.value == '' ? null : { 'namenonumbers': true };
        };
    }

    AlphaNumeric(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let regex = new RegExp(/^[a-z0-9 '/-]+$/i);
            const valid = regex.test(control.value);
            return valid || control.value == '' ? null : { 'alphanumeric': true };
        }
    }

    AlphaNumericHyphenApost(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let regex = new RegExp(/^[a-z0-9 '-]+$/i);
            const valid = regex.test(control.value);
            return valid || control.value == '' ? null : { 'alphanumerichyphenapost': true };
        }
    }

    AlphaHyphenApost(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let regex = new RegExp(/^[a-z '-]+$/i);
            const valid = regex.test(control.value);
            return valid || control.value == '' ? null : { 'alphahyphenapost': true };
        }
    }

    DateValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || control.value == '__/__/____') { return null; }
            let date = moment(control.value, 'MM/DD/YYYY');
            let minDate = moment('01/01/1760', 'MM/DD/YYYY');
            return date.isValid() && control.value.indexOf('_') == -1 && date >= minDate ? null : { 'datevalidator': true };
        };
    }

    StartDateValidator(endDate: string) {
        return (control: AbstractControl): { [key: string]: any } => {
            let end = moment(endDate, 'MM/DD/YYYY');
            if (!endDate || endDate == '__/__/____' || endDate.indexOf('_') > -1 || !end.isValid()) { return null; }
            let start = moment(control.value, 'MM/DD/YYYY');
            if (!control.value || control.value == '__/__/____' || control.value.indexOf('_') > -1 || !start.isValid()) { return null; }
            let valid = start < end;
            return valid ? null : { 'startdatevalidator': true };
        };
    }

    EndDateValidator(startDate: string) {
        return (control: AbstractControl): { [key: string]: any } => {
            let end = moment(control.value, 'MM/DD/YYYY');
            if (!control.value || control.value == '__/__/____' || control.value.indexOf('_') > -1 || !end.isValid()) { return null; }
            let start = moment(startDate, 'MM/DD/YYYY');
            if (!startDate || startDate == '__/__/____' || startDate.indexOf('_') > -1 || !start.isValid()) { return null; }
            let valid = start <= end;
            return valid ? null : { 'enddatevalidator': true };
        };
    }

    RequiredDateValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let date = moment(control.value, 'MM/DD/YYYY');
            let minDate = moment('01/01/1760', 'MM/DD/YYYY');
            return date.isValid() && control.value != '__/__/____' && control.value.indexOf('_') == -1 && date >= minDate ? null : { 'requireddatevalidator': true };
        };
    }

    RequiredTimeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let timeRegex = /^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/;
            let regex = new RegExp(timeRegex);
            const valid = regex.test(control.value);
            return valid ? null : { 'requiredtimevalidator': true };
        };
    }

    TimeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || control.value == '__:__ _M') { return null; }
            let timeRegex = /^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/;
            let regex = new RegExp(timeRegex);
            const valid = regex.test(control.value);
            return valid ? null : { 'timevalidator': true };
        };
    }
    WebAddressValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) { return null; }
            let urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
            let regex = new RegExp(urlRegex);
            const valid = regex.test(control.value);
            return valid ? null : { 'webaddressvalidator': true };
        };
    }

    FederalTaxIDValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) { return null; }
            let taxIDRegex = /[0-9]{2}-[0-9]{7}/;
            let regex = new RegExp(taxIDRegex);
            const valid = regex.test(control.value);
            return valid ? null : { 'federaltaxidvalidator': true };
        }
    }

    HasMostCurrentDate(dateArray: Date[], fieldToValidate: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) return null;
            if (!dateArray || dateArray.length == 0) { return null };
            let mostCurrentDate: Date;
            let groupDate = control.parent.get(fieldToValidate).value;//date field for FormGroup of current control being validated
            if (!moment(groupDate, 'MM/DD/YYYY').isValid()) { return { 'hasmostcurrentdate': true } } //this group doesn't have a valid date
            mostCurrentDate = max(dateArray);
            let valid = moment(new Date(groupDate)) >= moment(mostCurrentDate);
            return valid ? null : { 'hasmostcurrentdate': true };
        }
    }
    /**
     * validation for fields that are conditionally required if country is USA
     *
     * @param countryControl form control that represents country
     */
    RequiredIfUSA(countryControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let IsUSA = countryControl.value;
            if (IsUSA) {
                if (!control.value) {
                    return { 'requiredifusa': true, };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }

    MatchesWorkerClassification(classControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let cn = classControl.value;
            let g: string = control.value ? control.value.toUpperCase() : null;
            if (g === 'F') {
                if (cn.startsWith('Ordained')) {
                    return { 'matchesClassification': true };
                } else {
                    return null;
                }
            } else if (g === 'M') {
                if (cn.startsWith('Deac')) {
                    return { 'matchesClassification': true };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    }

    MMDDYYYYFormat(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || control.value == '__/__/____') { return null; }
            let regex = new RegExp(this.MMDDYYYY);
            const valid = regex.test(control.value);
            let date = moment(control.value, 'MM/DD/YYYY');
            return valid && date.isValid() ? null : { 'mmddyyyy_format': true };
        }
    }

    MMDDFormat(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || control.value == '__/__/____') { return null; }
            let regex = new RegExp(/(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])/);
            const valid = regex.test(control.value);
            let date = moment(control.value + '/2000', 'MM/DD/YYYY');
            return valid && date.isValid() ? null : { 'mmdd_format': true };
        }
    }



    /**
     * Date regexp for standard US date entry MM/DD/YYYY
     *
     * @memberof ValidationService
     */
    MMDDYYYY = /^(?:[0-9]{2}\/[0-9]{2}\/[0-9]{4}|__\/__\/____)$/;

    /**
     * RegExp matching only letters, hyphens, apostrophes, spaces, commas, periods
     * and slashes
     *
     * @memberof ValidationService
     */
    LHASpSlPattern = /^[a-z ',./-]+$/i;

    /**
     * RegExp matching only letters, hyphens, apostrophes, spaces
     *
     * @memberof ValidationService
     */
    LHASpPattern = /^[a-z '-]+$/i;

    /**
     * RegExp matching MON-YYYY month/year entry
     *
     * @memberof ValidationService
     */
    MMMYYYY = /^(?:(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-\d{4}|___-____)$/i

    /**
     * RegExp matching only MM/DD pattern
     *
     * @memberof Validation
     */
    MMDD = /^(?:0?[1-9]|1[0-2])\/(?:0?[1-9]|[12][0-9]|3[01])$/;

    CityValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) { return null; }
            let cityRegex = /^(?:\s+|.*unknown.*)$/i;
            let regex = new RegExp(cityRegex);
            const valid = !regex.test(control.value);
            return valid ? null : { 'cityvalidator': true };
        }
    }

    ZipValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) { return null; }
            let zipUSARegex = /^\d{5}(?:|-\d{4})$/;
            let regexUSA = new RegExp(zipUSARegex);
            const validUSA = regexUSA.test(control.value);
            return validUSA ? null : { 'zipvalidator': true };
        }
    }


    AddressValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let v: string = control.value;
            if (!v || v.trim()=='') { return null; }
            let address1Regex = /^(?:\s+|.*unknown.*)$/i;
            let regex = new RegExp(address1Regex);
            const valid = !regex.test(control.value);
            return valid ? null : { 'address1validator': true };
        }
    }

    PhoneValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) { return null; }
            let phoneRegex = /^\(\d{3}\)\d{3}-\d{4}$/;
            let regex = new RegExp(phoneRegex);
            const valid = regex.test(control.value);
            return valid ? null : { 'phonevalidator': true };
        }
    }

    RequiredPhoneValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let phoneRegex = /^\(\d{3}\)\d{3}-\d{4}$/;
            let regex = new RegExp(phoneRegex);
            const valid = regex.test(control.value);
            return valid && control.value != '(___)___-____' ? null : { 'phonevalidator': true };
        }
    }

    EmailValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) { return null; }
            let emailRegex = /^[a-z0-9-+.-_]+\@[a-z0-9-.]+\.[a-z]{2,}$/i;
            let regex = new RegExp(emailRegex);
            const valid = regex.test(control.value);
            return valid ? null : { 'emailvalidator': true };
        }
    }

    FutureYearValidator(year?: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!year) {
                if (!control.value || control.value == '' || isNaN(control.value)) { return null; }
            } else {
                if (year == '' || isNaN(<any>year)) { return null; }
            }
            let currentYear = new Date().getFullYear();
            let y = year ? +year : +control.value;
            let valid = y <= currentYear;
            return valid ? null : { 'futureyear': true };
        }
    }

    FutureDateValidator(date?: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!date) {
                if ((control.value && control.value.indexOf('_') > -1) || !control.value || control.value == '' || !moment(control.value, 'MM/DD/YYYY').isValid()) { return null; }
            } else {
                if (date == '' || !moment(date, 'MM/DD/YYYY').isValid() || date.length < 10) { return null; }
            }
            let currentDate = new Date();
            let d = date ? new Date(date) : new Date(control.value);
            let valid = d <= currentDate;
            return valid ? null : { 'futuredate': true };
        }
    }

    NegativeNumberValidator() {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || control.value == '' || isNaN(control.value)) { return null; }
            let num = +control.value;
            return num >= 0 ? null : { 'negativenumber': true };
        }

    }


    UniqueValidator(array: FormArray, UniqueFields: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let valid: boolean = true;
            let combinationArray = [];
            if (array && array.controls) {
                array.controls.forEach(p => {
                    let combination = "";
                    UniqueFields.forEach(f => combination += p.get(f).value);
                    combinationArray.push(combination);
                });
                combinationArray.forEach(p => {
                    //search for dupes
                    let count = combinationArray.filter((f) => { return f == p; }).length;
                    if (count > 1) {
                        valid = false;
                    }
                })
            }
            return valid ? null : { 'uniquevalidator': true };
        }
    }

    NotInValidator<T>(array: T[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return array.includes(control.value) ? { 'notin': true } : null;
        }
    }

    UniqueVCNumber(numbers: string[] = []): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let vcnum = control.value;
            if (numbers.length == 0 || isNaN(vcnum)) { return null; }
            let matches = numbers.filter(x => { return x == vcnum; }).length;
            return matches == 0 ? null : { 'uniquevcnumber': true };
        }
    }

    HasOnePreferred(array: FormArray): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let preferredCount = this.fieldValueCount(array, 'IsPreferred', true);
            return preferredCount == 1 ? null : { 'hasonepreferred': true };
        }
    }

    HasOneShipTo(array: FormArray): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let preferredCount = this.fieldValueCount(array, 'ShipTo', true);
            return preferredCount == 1 ? null : { 'hasoneshipto': true };
        }
    }


    HasOneCrossRefAddressType(array: FormArray): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let crossRefCount = this.fieldValueCount(array, 'TypeName', 'Cross Ref');
            return crossRefCount < 2 ? null : { 'hasonecrossref': true };
        }
    }

    IsNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value || control.value == '____') return null;
            let regex = new RegExp(/^\d+$/);
            const valid = regex.test(control.value);
            return valid || control.value == '' ? null : { 'isnumber': true };
        };
    }

    private fieldValueCount(array: FormArray, fieldName: string, value: any): number {
        let count: number = 0;
        if (!array || array.controls.length == 0) return 1;//validation should be negated if there are no controls present
        if (array && array.controls) {
            array.controls.forEach(c => {
                if (c.get(fieldName) && c.get(fieldName).value == value) {
                    count += 1;
                }
            })
        }
        return count;
    }
    /**Validation to check if the currently selected lookup value is obsolete
     * @param arrayOfFields lookup value collection used to search for the currently selected value
     * @param value selected value being validated
     * @param group FormGroup reference used to bind an error message if applicable
     * @param errorCollectionName Name of error collection array that's bound to FormGroup that will ultimately display a message if validation fails
     * @param errorMessage Error message that will be displayed if validation fails
    */
    FieldValueIsObsolete(arrayOfFields: any[], value: string, group: FormGroup, errorCollectionName: string, errorMessage: string): void {
        let groupMessage = group.get(errorCollectionName);
        let messageIndex: number = groupMessage.value ? groupMessage.value.indexOf(errorMessage, 0) : -1;
        if (messageIndex > -1) {
            let updatedWarnings = filter(groupMessage.value, (m) => { return m != errorMessage });//clear existing obsolete message (if any)
            group.patchValue({
                [errorCollectionName]: updatedWarnings
            })
        }
        let selectedType = filter(arrayOfFields, (pt) => { return pt.value == value })[0];
        if (selectedType && selectedType.IsObsolete) {
            groupMessage.value.push(errorMessage);//if obsolete, add error message
        }
    }

    /**Used with pKeyFilter to disallow commas to be entered in input elements */
    NoCommas = /^$|^[^,]+$/;

    /**
     * pKeyFilter RegExp to allow numbers only
     *
     * @memberof ValidationService
     */
    NumbersOnly = /^[0-9]*$/;

    IsAlphaNumeric(value: string): boolean {
        let re = /^[a-z0-9-]$/i;
        return re.test(value);
    }

    IsNumeric(value: string) {
        let re = /^[0-9-]$/;
        return re.test(value);
    }

    /**Generic handler for creating warnings for required dates that WON'T invalidate edit form
     * @param control reference to form control validation will be applied to
     * @param fieldName display friendly name of field validation will run against
     * @param errorArrayName name of array that contains collection of warnings
    */
    RequiredDateWarning(control: AbstractControl, fieldName: string, errorArrayName: string): SubscriptionLike {
        let warnings = [];
        let subscription: SubscriptionLike;
        let validateValue = function (value) {
            warnings = [];
            if (!value || value == '__/__/____') {
                warnings.push(`${fieldName} is required`);
            }
            control.parent.get(errorArrayName).setValue(warnings);
        }
        subscription = control.valueChanges.subscribe(v => {
            validateValue(v);
        })
        validateValue(control.value);
        return subscription;
    }

    /**Generic handler for creating warnings for required fields that WON'T invalidate edit form
    * @param control reference to form control validation will be applied to
    * @param fieldName display friendly name of field validation will run against
    * @param errorArrayName name of array that contains collection of warnings
   */
    RequiredValueWarning(control: AbstractControl, fieldName: string, errorArrayName: string): SubscriptionLike {
        let warnings = [];
        let subscription: SubscriptionLike;
        let validateValue = function (value) {
            warnings = [];
            if (!value || value == '') {
                warnings.push(`${fieldName} is required`);
            }
            control.parent.get(errorArrayName).setValue(warnings);
        }
        subscription = control.valueChanges.subscribe(v => {
            validateValue(v);
        })
        validateValue(control.value);
        return subscription;
    }

    HasGradeSelection(grades: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            let valid = grades && grades.length > 0;
            return valid ? null : { 'hasgradeselection': true };
        }
    }

    ValidateStatusHistoryEffectiveDate(editForm: FormGroup, dateArray: Date[], isCurrent: boolean) {
        let effectiveDateControl = editForm.get('EffectiveDate');
        let isCurrentControl = editForm.get('IsCurrent');
        let warningControl = editForm.get('EffectiveDateWarnings');
        let ID = editForm.get('ID').value;
        let effectiveDate = effectiveDateControl.value;
        if (dateArray.length == 0) {
            isCurrentControl.setValue(true);
        }
        else if (effectiveDateControl.value && moment(effectiveDate).isValid()) {
            let mostCurrentDate: Date;
            let maxdate = max(dateArray);
            mostCurrentDate = isCurrent && dateArray.length > 1 ? max(dateArray.filter(x => { return x != maxdate })) : max(dateArray);
            let valid = moment(new Date(effectiveDate)) >= moment(mostCurrentDate) || (ID != null && dateArray.length == 1);
            if (!valid) {
                warningControl.setValue([`Provided effective date is less than current effectvie date (${moment(mostCurrentDate).format('MM/DD/YYYY')}). Ignore this message if entry was intentional.`]);
            }
            isCurrentControl.setValue(valid);
        }

    }

}

