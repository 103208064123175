import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { SaveStatEnum } from '../enums/save.stat.enum';
import { CongregationStatService } from 'src/app/services/congregation.stat.service';
import { SubscriptionLike } from 'rxjs';
import { ICongregationStat } from 'src/app/model/stats/Icongregation.stat';
import { ConfirmationService } from 'primeng/api';

declare const $: any;
const md: any = {
    misc: {
        navbar_menu_visible: 0,
        active_collapse: true,
        disabled_collapse_init: 0,
    }
};

@Component({
    selector: 'app-fixedplugin',
    templateUrl: './fixedplugin.component.html',
    styleUrls: ['./fixedplugin.component.css']
})

export class FixedpluginComponent implements OnInit, OnDestroy {

    route: string;
    statsAreValid: boolean = false;
    statsHaveVariance: boolean;
    validStatsSub: SubscriptionLike;
    congStatSub: SubscriptionLike;
    congStatVarianceSub: SubscriptionLike;
    constructor(private router: Router, private appService: AppService, private authService: AuthService, private congStatService: CongregationStatService, private confirmationService: ConfirmationService) { }

    display(): boolean {
        return this.route.indexOf('/csr/step') > -1 || this.route.indexOf('/csr/rs') > -1;
    }

    // <!-- Review + Submit Button Group

    //   Loader One (loading):
    //   -Group hidden

    //   Accuracy Double Check: 
    //   -Group visible
    //   -Save enabled
    //   -Review + Submit enabled only if accuracy double check has been passed

    //   Loader Two (loading): 
    //   -Group hidden

    //   Final Review: 
    //   -Group visible
    //   -Save renamed Save and Return
    //   --on click will launch Save and Return Modal
    //   -Review + Submit renamed Finish + Submit
    //   --on click will launch Finish and Submit Modal

    // -->

    ngOnInit() {

        this.validStatsSub = this.congStatService.ValidStats$.subscribe(v => {
            this.statsAreValid = v;
        })

        this.congStatVarianceSub = this.congStatService.StatsHaveVariance$.subscribe(v => {
            this.statsHaveVariance = v;
        });

        this.route = this.router.url
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.route = val.url;
            }
        });
        const window_width = $(window).width();

        const fixed_plugin_open = $('.sidebar .sidebar-wrapper .nav li.active a p').html();

        if (window_width > 767 && fixed_plugin_open === 'Dashboard') {
            if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
                $('.fixed-plugin .dropdown').addClass('open');
            }
        }
    }

    SaveStats() {
        switch (this.route) {
            case '/csr/stepone':
            case '/csr/stepone?validateVariance=true':
                this.appService.saveStats(SaveStatEnum.MembershipAttendance);
                break;
            case '/csr/steptwo':
            case '/csr/steptwo?validateVariance=true':
                this.appService.saveStats(SaveStatEnum.Finances);
                break;
            case '/csr/stepthree':
                this.appService.saveStats(SaveStatEnum.EducationEnrollment);
                break;
            case '/csr/stepthree?tabindex=0':
                this.appService.saveStats(SaveStatEnum.EducationEnrollment);
                break;
            case '/csr/stepthree?tabindex=1':
                this.appService.saveStats(SaveStatEnum.EducationEnrollment);
                break;
            case '/csr/stepthree?tabindex=2':
                this.appService.saveStats(SaveStatEnum.EducationEnrollment);
                break;
            case '/csr/stepthree?tabindex=3':
                this.appService.saveStats(SaveStatEnum.EducationEnrollment);
                break;
            case '/csr/stepfour':
                this.appService.saveStats(SaveStatEnum.MembershipDemographics);
                break;
            case '/csr/rs':
                if (this.statsHaveVariance) {
                    this.appService.saveStats(SaveStatEnum.VarianceUpdate);
                } else {
                    this.appService.saveStats(SaveStatEnum.SubmitStats);
                }
                break;
        }



    }

    FinalSubmit() {
        this.confirmationService.confirm({
            message: "This is your final chance to review your data before it's sent to Rosters.",
            header: 'Submit Statistics',
            icon: 'fas fa-info-circle',
            acceptLabel: 'Submit',
            rejectLabel: 'Cancel',
            accept: () => {
                this.SaveStats();
                setTimeout(function () {
                    window.scrollTo(0, 0);
                }, 700);
            }
        });
    }
    SaveAndReturn() {
        this.confirmationService.confirm({
            message: "Your information has been saved and you may now logout. <br /><span class='sr-note'>Note: next time you log in you can pick up right where you left off.</span>",
            header: 'Save & Return',
            icon: 'fas fa-info-circle',
            acceptLabel: 'Logout',
            rejectLabel: 'Cancel',

            accept: () => {
                this.congStatService.AutoSaveStats();
                //mark route to return to /csr/rs on successful login
                this.authService.logOut({ backTo: '/csr/rs' });
            }
        });
    }
    NavigateToReview(): void {
        this.router.navigateByUrl('/csr/rs');
    }

    Tour(): void {
        switch (this.route) {
            case '/csr/stepone':
            case '/csr/stepone?validateVariance=true':
                this.router.navigate(['/csr/steptwo']);
                break;
            case '/csr/steptwo':
            case '/csr/steptwo?validateVariance=true':
                this.router.navigate(['/csr/stepthree']);
                break;
            case '/csr/stepthree':
                this.router.navigate(['/csr/stepthree'], { queryParams: { tabindex: '1' } });
                break;
            case '/csr/stepthree?tabindex=0':
                this.router.navigate(['/csr/stepthree'], { queryParams: { tabindex: '1' } });
                break;
            case '/csr/stepthree?tabindex=1':
                this.router.navigate(['/csr/stepthree'], { queryParams: { tabindex: '2' } });
                break;
            case '/csr/stepthree?tabindex=2':
                this.router.navigate(['/csr/stepthree'], { queryParams: { tabindex: '3' } });
                break;
            case '/csr/stepthree?tabindex=3':
                this.router.navigate(['/csr/stepfour']);
                break;
        }
    }  

    ngOnDestroy(): void {
        if (this.congStatSub) { this.congStatSub.unsubscribe(); }
        if (this.congStatVarianceSub) { this.congStatVarianceSub.unsubscribe(); }
        if (this.congStatVarianceSub) { this.congStatVarianceSub.unsubscribe(); }
        this.appService.clearSaveStats();
    }

}
