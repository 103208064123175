import { Component, Input } from '@angular/core';

@Component({
    selector:"form-control-error",
    template:`
    <div class="{{fcClass}}">
        <ul class="error-list">
            <li *ngFor="let error of Errors">{{error}}</li>
        </ul>
    </div>`
})
export class FormErrorComponent {
    @Input()Errors:string[];
    @Input('fcClass') fcClass: string ="text-danger";
}