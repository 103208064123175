import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { ActivatedRoute, Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CongregationStatService } from '../services/congregation.stat.service';
import { ICongregationInfo } from '../model/stats/Icongregation.info.model';
import { SubscriptionLike, Subscription } from 'rxjs';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material';
import { ICongregationStat } from '../model/stats/Icongregation.stat';
import { CSRService } from 'src/app/services/csr.service';
import { environment } from 'src/environments/environment';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    // NOTE: NAVIGATION is done via HTML due to flexibility issues with this approach
    // {
    //     path: '/dashboard',
    //     title: 'Dashboard',
    //     type: 'link',
    //     icontype: 'dashboard'
    // },{
    //     path: '/components',
    //     title: 'Congregation Statistics Reporting',
    //     type: 'sub',
    //     icontype: 'assignment',
    //     collapse: 'components',
    //     children: [
    //         {path: 'stepone', title: 'Congregation Membership & Attendance', ab:'01'},
    //         {path: 'steptwo', title: 'Finances', ab:'02'},
    //         {path: 'stepthree', title: 'Christian Education Enrollment', ab:'03'},
    //         {path: 'stepfour', title: 'Membership Demographics', ab:'04'}
    //     ]
    // }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
    shortScreen: boolean = false;
    public menuItems: any[];
    screenHeight: any;
    route: string;
    copydate: Date = new Date();
    CongInfo: ICongregationInfo;
    StatYears: string[] = [];
    CongStats: ICongregationStat;
    CongInfoSub: SubscriptionLike;
    CongStatSub: SubscriptionLike;
    ValidMembershipAttendanceSub: SubscriptionLike;
    ValidFinancesSub: SubscriptionLike;
    ValidDemographicsSub: SubscriptionLike;
    validMembershipAttendance: boolean;
    validFinances: boolean;
    validDemographics: boolean;

    statsAreValid: boolean = false;
    StatsAreValidSub: SubscriptionLike;

    sbcTabVisited: boolean;
    sbcTabVisitedSub: SubscriptionLike;

    wbcTabVisited: boolean;
    wbcTabVisitedSub: SubscriptionLike;

    ccTabVisited: boolean;
    ccTabVisitedSub: SubscriptionLike;
    statsOpen: boolean;
    congProfileUrl: string;
    vbsTabVisited: boolean;
    vbsTabVisitedSub: SubscriptionLike;
    authSub: SubscriptionLike;
    LaunchModalContact(): void {
        this.csrSvc.OpenDialogContact();
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private congStatService: CongregationStatService,
        private csrSvc: CSRService
    ) {}

    logOut() {
        this.authService.logOut({
            backTo: window.location.href,
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        this.shortScreen = false;
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        this.shortScreen = true;
    }

    checkScroll() {
        this.shortScreen = false;
    }

    upTop() {
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        elemSidebar.scrollTop = 0;
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.route = this.router.url;
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.route = val.url;
            }
        });

        this.sbcTabVisitedSub = this.congStatService.sbcTabVisited$.subscribe((v) => {
            this.sbcTabVisited = v;
        });

        this.wbcTabVisitedSub = this.congStatService.wbcTabVisited$.subscribe((v) => {
            this.wbcTabVisited = v;
        });

        this.ccTabVisitedSub = this.congStatService.ccTabVisited$.subscribe((v) => {
            this.ccTabVisited = v;
        });

        this.vbsTabVisitedSub = this.congStatService.vbsTabVisited$.subscribe((v) => {
            this.vbsTabVisited = v;
        });

        this.menuItems = ROUTES.filter((menuItem) => menuItem);
        this.CongInfoSub = this.congStatService.CongregationInfo$.subscribe((info) => {
            this.CongInfo = info;
            if (info && info.StatYears) {
                this.StatYears = info.StatYears.split(',');
            }
        });

        this.CongStatSub = this.congStatService.CongregationStats$.subscribe((s) => {
            this.CongStats = s;
        });

        this.authSub = this.authService.auth$.subscribe((auth) => {
            this.statsOpen = auth && auth.statsOpen;
            if (auth) {
                this.congProfileUrl = `${environment.lisnBaseUrl}/rosters/congregation${auth.admin ? ';id=' + auth.lookupID : ''}`;
            }
        });

        this.StatsAreValidSub = this.congStatService.ValidStats$.subscribe((v) => (this.statsAreValid = v));
        this.ValidMembershipAttendanceSub = this.congStatService.MembershipAttendanceIsValid$.subscribe(
            (v) => (this.validMembershipAttendance = v)
        );
        this.ValidFinancesSub = this.congStatService.FinancesAreValid$.subscribe((v) => (this.validFinances = v));
        this.ValidDemographicsSub = this.congStatService.DemographicsAreValid$.subscribe((v) => (this.validDemographics = v));
    }

    updatePS(): void {
        // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
        //     const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
        //     let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        // }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    OpenStatForm(year: string) {
        window.open('api/export/stats/' + year + '/' + this.CongInfo.LookupID);
    }

    GoToCongProfile(): void {
        window.open(this.congProfileUrl, '_self');
    }

    ngOnDestroy(): void {
        if (this.CongStatSub) {
            this.CongStatSub.unsubscribe();
        }
        if (this.CongInfoSub) {
            this.CongInfoSub.unsubscribe();
        }
        if (this.StatsAreValidSub) {
            this.StatsAreValidSub.unsubscribe();
        }
        if (this.ValidMembershipAttendanceSub) {
            this.ValidMembershipAttendanceSub.unsubscribe();
        }
        if (this.ValidFinancesSub) {
            this.ValidFinancesSub.unsubscribe();
        }
        if (this.ValidDemographicsSub) {
            this.ValidDemographicsSub.unsubscribe();
        }
        if (this.sbcTabVisitedSub) {
            this.sbcTabVisitedSub.unsubscribe();
        }
        if (this.wbcTabVisitedSub) {
            this.wbcTabVisitedSub.unsubscribe();
        }
        if (this.ccTabVisitedSub) {
            this.ccTabVisitedSub.unsubscribe();
        }
        if (this.vbsTabVisitedSub) {
            this.vbsTabVisitedSub.unsubscribe();
        }
    }
}
