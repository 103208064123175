import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from './services/auth.service';
import { CongregationStatService } from './services/congregation.stat.service';
import { SubscriptionLike } from 'rxjs';

declare const $: any;

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy {

  private _router: Subscription;
  CongregationInfoSubscription: SubscriptionLike;
  constructor(private router: Router, private authService: AuthService, private congStatService: CongregationStatService) {
  }

  ngOnInit() {
    this.authService.loggedIn();
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
    });
    this.CongregationInfoSubscription = this.authService.auth$.subscribe(a => {
      if (a && a.lookupID > 0) {
        this.congStatService.clearCongStatData();
        this.congStatService.getCongregationInfo(this.authService.getCongregationID());
        this.congStatService.getCongregationStats(this.authService.getCongregationID(), new Date().getFullYear() - 1);
        this.congStatService.getCongregationPrevStats(this.authService.getCongregationID());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.CongregationInfoSubscription) { this.CongregationInfoSubscription.unsubscribe(); }
    this.congStatService.clearCongStatData();
    this.authService.clearAuthenticated();
  }
}
