export class Address {
    ID: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    StateCodeID: string;
    Country: string;
    CountryCodeID: string;
    Zip: string;
    AddressTypeID: string;
}