export class ValidationMessages {
    required: string = ' is a required field';
    maxlength: string = ' has a max length of ';
    max: string = " has a max number of ";
    min: string = " has a minimum number of ";
    minlength: string = ' has a minimum length of ';
    namenonumbers: string = 'letters, spaces, hyphens, slashes, and apostrophes only';
    pattern: string = ' is invalid';
    datevalidator: string = " is invalid";
    requireddatevalidator: string = " requires a valid date";
    webaddressvalidator: string = " has an invalid URL";
    federaltaxidvalidator: string = " should be in 99-9999999 format";
    cityvalidator: string = " must be meaningful";
    zipvalidator: string = " is invalid";
    address1validator: string = " must be meaningful";
    phonevalidator: string = " is invalid";
    uniquevalidator: string = " must be unique";
    hasonepreferred: string = " must have at least one marked as preferred";
    hasoneshipto: string = " must have at least one marked as a ship to address";
    emailvalidator: string = " is invalid";
    requiredtimevalidator: string = " is invalid. (valid example 12:30 PM)";
    hasonecrossref: string = "There can be only one cross reference address type";
    mmddyyyy_format: string = " is invalid";
    mmdd_format: string = " is invalid";
    futureyear: string = " cannot be a future date";
    futuredate: string = " cannot be in the future";
    hasgradeselection: string = "At least one grade should be selected";
    alphanumeric: string = " letters, number, spaces, hyphens, slashes, and apostrophes only";
    isnumber: string = " must be numeric";
    timevalidator: string = " must be in format HH:MM AM/PM";
    duplicatedistid: string = ' is already in use';
    alphanumerichyphenapost: string = ' can only have letters, number, spaces, hyphens, and apostrophes';
    uniquevcnumber: string = ' is already in use';
    enddatevalidator: string = " must be greater than start date";
    startdatevalidator: string = " must be prior to end date";
    alphahyphenapost: string = " can only have letters, spaces, hyphens, and apostrophes";
    negativenumber: string = " cannot be a negative number";
    requiredifusa: string = " is required";
    notin: string = ' must be unique';
    assignmentdetailstartdate: string = ' is prior to most current detail record start date. Please correct if in error or set an End Date.';
}
