import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AuthService } from '../app/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FooterModule } from './shared/footer/footer.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from '../app/shared/shared.module';
import { AppRoutes } from './app.routing';
import { ValidationService } from './services/validation.service';
import { AppService } from './services/app.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { httpInterceptorProviders } from './shared/interceptors/interceptor';
import { LayLeaderService } from './services/layleaders.service';
import { BrowserModule } from '@angular/platform-browser';
import { FixedpluginComponent } from './shared/fixedplugin/fixedplugin.component';
import { SidebarComponent } from './sidebar/sidebar.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    DeviceDetectorModule.forRoot(),
    FooterModule,
    HttpClientModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    FixedpluginComponent,
    SidebarComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    ValidationService,
    AppService,
    MessageService,
    httpInterceptorProviders,
    CurrencyPipe,
    DecimalPipe,
    LayLeaderService
  ]
})
export class AppModule { }
