import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Injectable()
export class StatsGuardService implements CanActivate {
    constructor(public auth: AuthService, public router: Router, ) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const congId = route.queryParams.congId;
        if (!this.auth.statsOpen()) {
            this.router.navigateByUrl('/csr/rs' + (congId ? `?congId=${congId}` : ''));
            return false;
        }
        return true;
    }
}