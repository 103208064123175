import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from '../app/services/auth.guard.service'

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuardService]
    }, {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'csr',
                loadChildren: './csr/csr.module#CsrModule',
                canActivate: [AuthGuardService]
            },
            {
                path: 'layleaders',
                loadChildren: './layleaders/layleaders.module#LLModule',
                canActivate: [AuthGuardService]
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
