import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IAuthorized } from '../model/stats/IAuthorized';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private emptyIAuthorized: IAuthorized = {
        admin: false,
        authenticated: false,
        lookupID: 0,
    };

    private authSubject: BehaviorSubject<IAuthorized> = new BehaviorSubject<IAuthorized>(this.emptyIAuthorized);
    auth$: Observable<IAuthorized> = this.authSubject.asObservable();

    constructor(private http: HttpClient, route: ActivatedRoute) {
        route.queryParams.subscribe((params) => {
            if (params.congId) {
                this.http.get('/api/passthrough/' + params.congId).subscribe((result: IAuthorized) => {
                    if (typeof result.statsOpen === 'string') {
                        result.statsOpen = result.statsOpen === 'true';
                    }
                    this.setIAuthorized(result);
                });
            }
        });
    }

    public login(url?: string) {
        setTimeout(() => {
            this.http.get<{ redirect: string | null; user?: IAuthorized }>('/api/account/login').subscribe((result) => {
                if (result.redirect) {
                    const redirect =
                        result.redirect +
                        (result.redirect.indexOf('?') >= 0 ? '&' : '?') +
                        'backTo=' +
                        encodeURIComponent(window.location.protocol + '//' + window.location.host + (url || ''));
                    window.location.href = redirect;
                    return;
                }
                if (result.user) {
                    if (typeof result.user.statsOpen === 'string') {
                        result.user.statsOpen = result.user.statsOpen === 'true';
                    }
                    this.authSubject.next(result.user);
                }
            });
        }, 500);
    }

    setIAuthorized(authorized: IAuthorized) {
        if (typeof authorized.statsOpen === 'string') {
            authorized.statsOpen = authorized.statsOpen === 'true';
        }
        this.authSubject.next(authorized);
    }

    logOut(params: { backTo: string }) {
        this.http.get<{ success: boolean; redirect: string }>('/api/logout').subscribe((a) => {
            setTimeout(() => {
                window.location.href =
                    a.redirect +
                    '?backTo=' +
                    encodeURIComponent(
                        '/login?application=10594881-5A94-4354-8381-9A186405D8E4&backTo=' + encodeURIComponent(params.backTo)
                    );
            }, 200);
        });
    }

    isAuthenticated(): boolean {
        return this.authSubject && this.authSubject.value && this.authSubject.value.authenticated;
    }

    loggedIn() {
        this.http.get<IAuthorized>('/api/loggedin').subscribe((a) => {
            if (typeof a.statsOpen === 'string') {
                a.statsOpen = a.statsOpen === 'true';
            }
            this.authSubject.next(a);
        });
    }

    getCongregationID() {
        return this.authSubject && this.authSubject.value ? this.authSubject.value.lookupID : null;
    }

    statsOpen(): boolean {
        return this.authSubject && this.authSubject.value.statsOpen;
    }

    clearAuthenticated() {
        this.authSubject.next(null);
    }
}
