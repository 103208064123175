import { Component, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { CSRService } from 'src/app/services/csr.service';

@Component({
    selector: 'app-contact-modal',
    templateUrl: './contact.component.html'
})
export class ContactModalComponent implements OnChanges, OnInit {
  
    DisplayDialogContact: boolean = false;

    constructor(private csrSvc: CSRService) {  }
    ngOnChanges(changes) {

    }
    ngOnInit() {
      this.csrSvc.DisplayDialogContact$.subscribe(d => this.DisplayDialogContact = d);
    }

    CloseSideMenu() {

    }

    

    CloseModal() {
        this.csrSvc.CloseDialogContact();
    }


}




