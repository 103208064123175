import { Component, OnInit, OnDestroy, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'lodash';
import { SelectItem } from 'primeng/components/common/selectitem';
import { ValidationService } from '../../services/validation.service';
import { FormUtilityService } from '../../services/formUtility.service';
import { SubscriptionLike } from 'rxjs';
import { Address } from 'src/app/model/Address';
import { Country } from 'src/app/model/country';
import { State } from 'src/app/model/state';
import { LayLeaderService } from 'src/app/services/layleaders.service';
import { isEmpty } from 'lodash';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'address-form',
    templateUrl: './address.form.component.html'
})
export class AddressFormComponent implements OnInit, OnDestroy, OnChanges {

    EditForm: FormGroup;
    @Input() Address: Address = null;
    @Input() EntityAddresses: Address[];
    @Input() IsNew: boolean;
    @Input() AddressTypes: SelectItem[] = [];
    NoCommas: RegExp = this.validationService.NoCommas;
    States: State[];
    SelectedState: State;
    Countries: Country[];
    filteredStates: string[];
    filteredCountries: string[];
    IsUSA: boolean;
    @Output() OnAddressTypeChanged: EventEmitter<SelectItem> = new EventEmitter();
    @Output() OnAddressValidation: EventEmitter<void> = new EventEmitter();
    @Output() OnAddressTypesLoaded: EventEmitter<boolean> = new EventEmitter();
    @Output() OnDataBound: EventEmitter<boolean> = new EventEmitter();
    StateListSubscription: SubscriptionLike;
    CountriesListSubscription: SubscriptionLike;
    countryControlSub: SubscriptionLike;
    lookupSub: SubscriptionLike;
    constructor(private fb: FormBuilder, private layleaderService: LayLeaderService,
        private validationService: ValidationService, private formUtilityService: FormUtilityService, private confirmationService: ConfirmationService) {
        this.EditForm = this.fb.group({
            AddressID: null,
            Address1: ['', [Validators.required, Validators.maxLength(55),
            this.validationService.AddressValidator()]],
            Address2: ['', [Validators.maxLength(55), this.validationService.AddressValidator()]],
            City: ['', [Validators.required, Validators.maxLength(40)
                , this.validationService.CityValidator()]],
            State: [''],
            StateCodeID: null,
            Zip: [''],
            AddressTypeID: [null, [Validators.required]],
            Country: ['', [Validators.required]],
            CountryCodeID: [null],
            StateErrorMessages: [],
            CityErrorMessages: [],
            ZipErrorMessages: [],
            Address1ErrorMessages: [],
            Address2ErrorMessages: [],
            AddressTypeIDErrorMessages: [],
            CountryErrorMessages: []
        });
        this.addControlValidationHandler(this.EditForm);
    }

    addControlValidationHandler(group: FormGroup) {

        //state validation
        this.formUtilityService.ControlValidationHandler(group.get('State'), 'StateErrorMessages', 'State');

        //city validation
        this.formUtilityService.ControlValidationHandler(group.get('City'), 'CityErrorMessages', 'City');

        //zip validation
        this.formUtilityService.ControlValidationHandler(group.get('Zip'), 'ZipErrorMessages', 'Zip', {
            zipvalidator: 'Zip must be 5 digits with optional Plus-4 when Country is USA',
            required: ' Zip required when Country is USA or CANADA',
            maxlength: 'Zip code can be no more than 10 characters long'
        });

        //address 1 validation
        this.formUtilityService.ControlValidationHandler(group.get('Address1'), 'Address1ErrorMessages', 'Address 1');

         //address 2 validation
         this.formUtilityService.ControlValidationHandler(group.get('Address2'), 'Address2ErrorMessages', 'Address 2');

        //address type validation
        this.formUtilityService.ControlValidationHandler(group.get('AddressTypeID'), 'AddressTypeIDErrorMessages', 'Address Type');

        //country validation
        this.formUtilityService.ControlValidationHandler(group.get('Country'), 'CountryErrorMessages', 'Country');

    }

    private setConditionalValidation() {
        //conditional validation for state/zip based on country value
        let countryControl = this.EditForm.get('Country');
        if (this.countryControlSub) { this.countryControlSub.unsubscribe(); }
        this.countryControlSub = countryControl.valueChanges.subscribe(v => {
            let stateControl = this.EditForm.get('State');
            stateControl.clearValidators();
            let zipControl = this.EditForm.get('Zip');
            zipControl.clearValidators();
            this.IsUSA = v == "USA";
            switch (v) {
                case 'USA':
                    stateControl.setValidators([Validators.required, Validators.maxLength(2)]);
                    zipControl.setValidators([Validators.required, this.validationService.ZipValidator()]);
                    break;
                case 'CANADA':
                    stateControl.setValidators([Validators.required, Validators.maxLength(2)]);
                    zipControl.setValidators([Validators.required]);
                    break;
                default:
                    stateControl.setValidators([Validators.maxLength(2)]);

            }
            stateControl.updateValueAndValidity();
            zipControl.updateValueAndValidity();
        })
        //conditional validation for address 1
        this.OnAddressValidation.emit(null);
    }


    StateChanged(stateObj: State) {
        if (stateObj && this.EditForm.get('State').value == stateObj.Abbreviation) return;
        this.SelectedState = stateObj;
        let countryName = '';
        let countryID = '';
        let stateID = null;
        let state = '';
        if (stateObj) {
            stateID = stateObj.ID;
            state = stateObj.Abbreviation;
            //auto populate country if valid state is selected
            let country: Country = this.Countries.filter(country => country.ID == stateObj.CountryID)[0];
            countryName = country ? country.Name : '';
            countryID = country ? country.ID : null;
        }
        this.EditForm.get('StateCodeID').setValue(stateID);
        this.EditForm.get('State').setValue(state);
        this.EditForm.get('Country').setValue(countryName);
        this.EditForm.get('CountryCodeID').setValue(countryID);
        this.EditForm.markAsDirty();
        this.EditForm.get('Zip').setValue(null);
    }

    CountryChanged(countryObj: Country): void {
        if (countryObj && this.EditForm.get('Country').value == countryObj.Name) return;
        this.EditForm.get('State').setValue(null);
        this.EditForm.get('StateCodeID').setValue(null);
        this.EditForm.get('Zip').setValue(null);
        this.EditForm.get('Country').setValue(countryObj ? countryObj.Name : '');
        this.EditForm.get('CountryCodeID').setValue(countryObj ? countryObj.ID : null);
        this.EditForm.markAsDirty();
    }

    ZipBlur(e) {
        this.EditForm.get('Zip').setValue(e.target.value);
    }

    AddressTypeChange(e): void {
        let addressType = filter(this.AddressTypes, (t) => { return t.value == e.value })[0];
        this.OnAddressTypeChanged.emit(addressType);       
    }

    GetFormData(): Address {
        let address: Address = new Address();
        if (this.EditForm.valid) {
            Object.keys(this.EditForm.controls).forEach((key) => {
                address[key] = this.EditForm.get(key).value;
            })
        }
        return address
    }

    ngOnChanges(changes): void {
        //dynamically add attribute controls      
        let address: Address = changes['Address'] && !isEmpty(changes['Address'].currentValue) ? changes['Address'].currentValue : null;
        if (!address) { address = new Address() }
        this.IsUSA = address.Country == "USA";
        this.EditForm.patchValue(
            {
                AddressID: address.ID,
                Address1: address.Address1,
                Address2: address.Address2,
                City: address.City,
                State: address.State,
                StateCodeID: address.StateCodeID,
                AddressTypeID: address.AddressTypeID,              
                Country: address.Country,
                CountryCodeID: address.CountryCodeID,
                Zip: address.Zip
            });

        this.setConditionalValidation();
        let selectedAddressType = this.AddressTypes.filter(x => { return address && x.value == address.AddressTypeID })[0];
        if (selectedAddressType) { this.OnAddressTypeChanged.emit(selectedAddressType) };
        //if there's only one address type, default to that value
        if (this.AddressTypes && this.AddressTypes.length == 1) {
            this.EditForm.get('AddressTypeID').setValue(this.AddressTypes[0].value);
        }
        this.formUtilityService.MarkFormGroupAsTouched(this.EditForm);
        this.EditForm.markAsPristine();
        this.OnDataBound.emit(true);
    }

    ngOnInit() {
      
        //populate country and state lookup lists
        this.StateListSubscription = this.layleaderService.StatesList$.subscribe(s => this.States = s);
        this.CountriesListSubscription = this.layleaderService.CountriesList$.subscribe(c => this.Countries = c);
    }

    ngOnDestroy(): void {
        if (this.StateListSubscription) { this.StateListSubscription.unsubscribe(); }
        if (this.CountriesListSubscription) { this.CountriesListSubscription.unsubscribe(); }
        if (this.countryControlSub) { this.countryControlSub.unsubscribe(); }
    }
}
