import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { LayLeader } from '../model/layleaders/layleader';
import { Behavior } from 'ng2-select';
import { ILookupValue } from '../model/ILookupValue';
import { Country } from '../model/country';
import { State } from '../model/state';


@Injectable({
    providedIn: 'root'
})
export class LayLeaderService {

    LayLeadersSubject: BehaviorSubject<LayLeader[]> = new BehaviorSubject<LayLeader[]>([]);
    LayLeaders$: Observable<LayLeader[]> = this.LayLeadersSubject.asObservable();

    private DisplayDialogAdd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    DisplayDialogAdd$: Observable<boolean> = this.DisplayDialogAdd.asObservable();

    private LookupTables: BehaviorSubject<{ [index: string]: ILookupValue[] }> = new BehaviorSubject<{ [index: string]: ILookupValue[] }>({});
    LookupTables$: Observable<{ [index: string]: ILookupValue[] }> = this.LookupTables.asObservable();

    private CountriesList: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
    CountriesList$: Observable<Country[]> = this.CountriesList.asObservable();

    private StatesList: BehaviorSubject<State[]> = new BehaviorSubject<State[]>([]);
    StatesList$: Observable<State[]> = this.StatesList.asObservable();

    constructor(private http: HttpClient) {

    }

    GetCountries() {
        this.http.get<Country[]>('/api/countries')
            .subscribe(cs => this.CountriesList.next(cs));
    }

    GetLayLeaders(CongID: string): void {
        this.http.get<LayLeader[]>('/api/layleaders/' + CongID).subscribe(l => {
            this.LayLeadersSubject.next(l);
        });
    }

    RemoveLayLeaderAssignment(assignID: string, assignDetailID: string): Observable<boolean> {
        return this.http.post<boolean>('/api/layleaders/remove/assignment', { assignID, assignDetailID });
    }

    GetMultipleLookupTables(tableNames: string[]) {
        this.http.post<ILookupValue[][]>('api/mlookup', tableNames)
            .subscribe(data => {
                let out: { [index: string]: ILookupValue[] } = {};
                for (let x in tableNames) {
                    out[tableNames[x]] = data[x];
                }
                this.LookupTables.next(out);
            });
    }

    CloseDialogAdd(): void {
        this.DisplayDialogAdd.next(false);
    }

    OpenDialogAdd(): void {
        this.DisplayDialogAdd.next(true);
    }

    GetStates() {
        this.http.get<State[]>('/api/states')
            .subscribe(s => {
                this.StatesList.next(s);
            }, () => {
            });
    }

    UpdateLayLeader(layleader: LayLeader): Observable<boolean> {
        return this.http.post<boolean>('/api/layleader/update', layleader);
    }

    CreateLayLeader(layleader: LayLeader): Observable<boolean>{
        return this.http.post<boolean>('/api/layleader/create', layleader);
    }

    ReplaceLayLeader(layleader: LayLeader): Observable<boolean>{
        return this.http.post<boolean>('/api/layleader/replace', layleader);
    }

}
