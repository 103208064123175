import { Component, Output, EventEmitter, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { AutoComplete } from 'primeng/components/autocomplete/autocomplete';
import { State } from '../../model/state';
import { LayLeaderService } from '../../services/layleaders.service';

@Component({
    selector: 'state-lookup',
    template: `
    <p-autoComplete  #stateLookup
        [(ngModel)]="value"
        placeholder="Enter a state..." 
        [forceSelection]="true" 
        (onClear)="ClearState()" 
        (onBlur)="StateBlur($event)"      
        (onSelect)="SelectState($event)" 
        [suggestions]="FilteredStates" 
        (completeMethod)="FilterStates($event)" 
        [delay]="25"
        [autoHighlight]="true"
        [style]="{'width':'100%'}" 
        styleClass="form-control"
        appendTo="body"
        autocomplete="doNotAutoComplete">
    </p-autoComplete>`
})
export class StateLookupComponent implements OnInit, OnDestroy {
    @ViewChild('stateLookup') autocomplete: AutoComplete;
    private _value: string;
    get value(): string {
        return this._value;
    };
    @Input()
    set value(value: string) {
        this._value = value;
        if (this.autocomplete && this.autocomplete.inputEL) {
            this.autocomplete.inputEL.nativeElement.value = value;
        }
    }
    @Output() OnSelect: EventEmitter<State>
        = new EventEmitter();
    @Output() OnClear: EventEmitter<void> = new EventEmitter();
    States: State[] = [];
    FilteredStates: string[];
    StateSub: SubscriptionLike;

    constructor(private layLeaderService: LayLeaderService) { }

    ngOnInit(): void {
        this.StateSub = this.layLeaderService.StatesList$.subscribe(s => this.States = s);
    }

    ngOnDestroy(): void {
        if (this.StateSub) { this.StateSub.unsubscribe(); }
    }

    StateBlur(e) {
        this.SelectState(e.target.value);
    }

    SelectState(value: string) {
        let selectedState = this.States.filter(x => x.Abbreviation == value)[0];
        this.OnSelect.emit(selectedState);
    }

    OnSelectState(e) {
        this.SelectState(e)
    }

    FilterStates(event: any) {
        this.FilteredStates = this.States
            .map(x => x.Abbreviation)
            .filter(x => x.toLowerCase().startsWith(event.query.toLowerCase()))
            .sort();
    }

    ClearState(): void {
        this.OnClear.emit(null);
    }
}