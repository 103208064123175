import { Component, Output, EventEmitter, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { AutoComplete } from 'primeng/components/autocomplete/autocomplete';
import { LayLeaderService } from 'src/app/services/layleaders.service'
import { Country } from 'src/app/model/country';


@Component({
    selector: 'country-lookup',
    template: `
    <p-autoComplete  #countryLookup
        [(ngModel)]="value"
        placeholder="Enter a country..." 
        [forceSelection]="true"  
        (onClear)="ClearCountry()"
        (onBlur)="CountryBlur($event)"      
        (onSelect)="SelectCountry($event)" 
        [suggestions]="FilteredCountries" 
        (completeMethod)="FilterCountries($event)" 
        [delay]="25"
        [autoHighlight]="true"
        [style]="{'width':'100%'}" 
        styleClass="form-control"
        appendTo="body">
    </p-autoComplete>`
})
export class CountryLookupComponent implements OnInit, OnDestroy {
    @ViewChild('countryLookup') autocomplete: AutoComplete;
    private _value: string;
    get value(): string {
        return this._value;
    };
    @Input()
    set value(value: string) {
        this._value = value;
        if (this.autocomplete && this.autocomplete.inputEL) {
            this.autocomplete.inputEL.nativeElement.value = value;
        }
    }
    @Output() OnSelect: EventEmitter<Country>
        = new EventEmitter();
    @Output() OnClear: EventEmitter<void> = new EventEmitter();
    Countries: Country[] = [];
    FilteredCountries: string[];
    CountrySub: SubscriptionLike;

    constructor(private layleaderService: LayLeaderService) { }

    ngOnInit(): void {      
        this.CountrySub = this.layleaderService.CountriesList$.subscribe(s => this.Countries = s);
    }

    ngOnDestroy(): void {
        if (this.CountrySub) { this.CountrySub.unsubscribe(); }
    }

    CountryBlur(e) {
        this.SelectCountry(e.target.value);
    }

    SelectCountry(value: string) {
        let selectedCountry = this.Countries.filter(x => x.Name == value)[0];
        this.OnSelect.emit(selectedCountry);
    }

    OnSelectCountry(e) {
        this.SelectCountry(e)
    }

    ClearCountry(): void {
        this.OnClear.emit(null);
    }

    FilterCountries(event: any) {
        this.FilteredCountries = this.Countries
            .map(x => x.Name)
            .filter(x => x.toLowerCase().startsWith(event.query.toLowerCase()))
            .sort();
    }
}