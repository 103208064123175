import { Injectable } from "@angular/core";
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { ValidationService } from "../services/validation.service";
import { AbstractControl } from "@angular/forms/src/model";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { debounceTime } from 'rxjs/operators';


export interface IFormState {
    CanSave: boolean;
    IsValid: boolean;
    IsDirty: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FormUtilityService {

    constructor(private validationService: ValidationService) {

    }

    /**Generic method used to clear all values from a particular form (FormGroups contained in a FormArray) excluding the value from the FormGroup you pass in.
     * @param group group that will be excluded from the value clearing process
     * @param field field whose value will be cleared
     * @param value value that will be set on specified FormGroup controls
     * @param array FormArray that contains the FormGroups whose values will be cleared
     */
    ClearOtherFormValues(group: FormGroup, field: string, value: any, array: FormArray) {
        let currentID = group.get('ID').value;
        array.controls.forEach(e => {
            let ID = e.get('ID').value;
            if (ID != currentID) {
                e.patchValue({
                    [field]: value
                })
            }
        })
    }

    /**Generic method used to bind form validation handler to form controls.
    * @param control abstract control to bind validation to
    * @param errorMessageName string name of collection used to display error messages (used with form-control-error component)
    * @param fieldDisplayName user friendly display name of field that will be displayed in error message
    * @param ErrorMessageOverride Optional object that can be used to override the default error messages defined in validation.messages.ts (i.e {required:'this is my customer error message'})
    */
    ControlValidationHandler(control: AbstractControl, errorMessageName: string,
        fieldDisplayName: string, ErrorMessageOverride: object = {}): void {
        control.valueChanges.pipe(
            debounceTime(this.validationService.ValidationDelay)
        ).subscribe(v => {
            let errors = this.validationService
                .validateFormControl(control, fieldDisplayName, ErrorMessageOverride);
            let group = <FormGroup>control.parent;
            group.patchValue({
                [errorMessageName]: errors
            })
        });
    }

    /**Generic method used to bind form validation handler to a FormArray.
   * @param formArray FormArray to bind validation to
   * @param errorMessageName string name of collection used to display error messages (used with form-control-error component)
   * @param fieldDisplayName user friendly display name of field that will be displayed in error message
   * @param errorControl AbstractControl the error will be attached to
   * @param ErrorMessageOverride Optional object that can be used to override the default error messages defined in
   * validation.messages.ts (i.e {required:'this is my customer error message'})
   */
    FormArrayValidationHandler(formArray: FormArray, errorMessageName: string, fieldDisplayName: string,
        errorControl: AbstractControl, ErrorMessageOverride: object = {}): void {
        formArray.valueChanges.subscribe(v => {
            let errors: any = this.validationService.validateFormControl(formArray, fieldDisplayName, ErrorMessageOverride);
            errorControl.patchValue([errorMessageName] = errors);
        });
    }

    /**Method to get number of month from 3 character month name abbreviation
    * @param abbrev 3 character month abbreviation (i.e. JAN, FEB, MAR)
    */
    MonthNumberFromAbbreviation(abbrev: string): string {
        let monthTransalator ={
            'JAN': '01',
            'FEB': '02',
            'MAR': '03',
            'APR': '04',
            'MAY': '05',
            'JUN': '06',
            'JUL': '07',
            'AUG': '08',
            'SEP': '09',
            'OCT': '10',
            'NOV': '11',
            'DEC': '12'
        }
        let translation = monthTransalator[abbrev.toUpperCase()] || '';
        return translation;
    }

    /**method used to mark all controls as touched. This is useful in situations where you want the controls to perform validation upon form load
   * @param formGroup FormGroup object that should have all top-level controls set to touched
   */
    MarkFormGroupAsTouched(formGroup: FormGroup) {
        Object.keys(formGroup.controls).map(x => formGroup.controls[x]).forEach(control => {
            control.markAsTouched();
            control.markAsDirty();
            control.updateValueAndValidity();
        });
    }

    BoolErrorClass(fg: FormGroup, e: string, ifTrue: string, ifFalse: string) {
        let he = this.HasErrors(fg, e);
        let out: { [index: string]: boolean } = {};
        out[ifTrue] = he;
        out[ifFalse] = !he;
        return out;
    }

    BoolClass(fg: FormGroup, e: string, ifTrue: string, ifFalse: string) {
        let he = fg.get(e).value;
        let out: { [index: string]: boolean } = {};
        out[ifTrue] = he;
        out[ifFalse] = !he;
        return out;
    }

    PullClass(fg: FormGroup, e: string) {
        return this.BoolErrorClass(fg, e, 'pull-left', 'pull-right');
    }

    CheckBoxClass(fg: FormGroup, e: string) {
        return this.BoolClass(fg, e, 'on_chk', 'off_chk');
    }

    HasErrors(fg: FormGroup, e: string) {
        let c = fg.get(e);
        return c && c.value && c.value.length > 0;
    }
    /**Generic method used to sort FormArray by a given field.
    * @param formArray FormArray to sort
    * @param sortBy string name of field to sort by
    */
    SortFormArray(formArray: FormArray, sortBy: string): void {
        formArray.controls.sort(function (x) { return x.get(sortBy).value == true ? 0 : 1; });
    }

    private FormStateSubject: BehaviorSubject<{ [key: string]: IFormState }> = new BehaviorSubject<{ [key: string]: IFormState }>({});
    FormState$: Observable<{ [key: string]: IFormState }> = this.FormStateSubject.asObservable();
    //set form state
    SetFormState(form: FormGroup, formID: string) {
        this.FormStateSubject.value[formID] = {
            IsValid: form.valid,
            CanSave: form.valid && form.dirty,
            IsDirty: form.dirty
        }
        this.FormStateSubject.next(this.FormStateSubject.value);
    }

    /**Generic method used by forms to listen for save commands
     * @param formName name of form to process save request
    */
    private SaveFormSubject: Subject<string> = new Subject<string>();
    SaveForm$: Observable<string> = this.SaveFormSubject.asObservable();
    SaveForm(formName: string) {
        this.SaveFormSubject.next(formName);
    }

    /**Generic method used by forms to listen for reset commands
     * @param formName name of form to process reset request
    */
    private ResetFormSubject: Subject<string> = new Subject<string>();
    ResetForm$: Observable<string> = this.ResetFormSubject.asObservable();
    ResetForm(formName: string) {
        this.ResetFormSubject.next(formName);
    }
}
