import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModule } from 'primeng/message';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CongregationInfoComponent } from '../components/congregation/congregation.info.component';
import { AuthGuardService } from '../services/auth.guard.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CountryLookupComponent } from './components/country.lookup.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { StateLookupComponent } from './components/state.lookup.component';
import { FormErrorComponent} from './components/form.error.message';
import { AddressFormComponent } from './components/address.form.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule
} from '@angular/material';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { NavbarModule } from './navbar/navbar.module';
import { ToastModule } from 'primeng/toast';
import { ContactModalComponent } from './contact/contact.component';
import { StatsGuardService } from '../csr/guards/StatsGuardService';
@NgModule({
  declarations: [
    CongregationInfoComponent,
    CountryLookupComponent,
    StateLookupComponent,
    FormErrorComponent,
    AddressFormComponent,
    ContactModalComponent
  ],
  imports: [
    CommonModule,
    MessageModule,
    ReactiveFormsModule,
    AutoCompleteModule, 
    FormsModule,
    DialogModule,
    InputMaskModule,
    KeyFilterModule,    
    DropdownModule,
    TabViewModule,
    TooltipModule,
    AccordionModule,   
    ProgressSpinnerModule,    
    TableModule, 
    NavbarModule,
    ToastModule,
  ],
  exports: [
    MessageModule,
    CongregationInfoComponent,
    ReactiveFormsModule,
    ConfirmDialogModule,
    CountryLookupComponent,
    AutoCompleteModule,
    FormsModule,
    StateLookupComponent,
    FormErrorComponent,
    AddressFormComponent,
    DialogModule,
    InputMaskModule,
    KeyFilterModule,   
    DropdownModule,
    TabViewModule,
    TooltipModule,
    AccordionModule,   
    ProgressSpinnerModule,    
    TableModule,    
    NavbarModule,
    ToastModule,
    ContactModalComponent,
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,    
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  providers: [
    AuthGuardService,
    ConfirmationService,
    StatsGuardService
  ]
})
export class SharedModule { }
