import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SaveStatEnum } from '../shared/enums/save.stat.enum';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private messageService: MessageService) { }

  private SaveStatsSubject: Subject<SaveStatEnum> = new Subject<SaveStatEnum>();
  SaveState$: Observable<SaveStatEnum> = this.SaveStatsSubject.asObservable();

  displayMessage(type: string, message: string, title: string, delay: number = 3000) {
    this.messageService.add({ severity: type, summary: title, detail: message, life: delay });
  }

  hideMessage() {
    this.messageService.clear();
  }

  saveStats(saveStatStep: SaveStatEnum) {
    this.SaveStatsSubject.next(saveStatStep);
  }

  clearSaveStats() {
    this.SaveStatsSubject.next(null);
  }


}
